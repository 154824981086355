import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/SelectedNews',
    name: 'selectedNews',
    component: () => import('../views/SelectedNewsView.vue')
    
  },
  {
    path: '/News',
    name: 'news',
    component: () => import('../views/NewsView.vue')
    
  },
  {
    path: '/AllNews',
    name: 'allNews',
    component: () => import('../views/AllNewsView.vue')
    
  },
  {
    path: '/GYIK',
    name: 'gyik',
    component: () => import('../views/GYIKView.vue')
    
  },
  {
    path: '/Kapcsolat',
    name: 'kapcsolat',
    component: () => import('../views/KapcsolatView.vue')
    
  },
  {
    path: '/Minosegpolitika',
    name: 'minosegpolitika',
    component: () => import('../views/MinosegpolitikaView.vue')
    
  },
  {
    path: '/AgazatInfo',
    name: 'agazatInfo',
    component: () => import('../views/AgazatInfoView.vue')
    
  },
  {
    path: '/Szabalyzatok',
    name: 'szabalyzatok',
    component: () => import('../views/SzabalyzatokView.vue')
    
  },
  {
    path: '/Tajekoztatok',
    name: 'tajekoztatok',
    component: () => import('../views/TajekoztatokView.vue')
    
  },
  {
    path: '/Szabalyzat',
    name: 'szabalyzat',
    component: () => import('/')
    
  },
  {
    path: '/Tajekoztato',
    name: 'tajekoztato',
    component: () => import('/')
    
  },
  {
    path: '/SzakmaInfo',
    name: 'szakmaInfo',
    component: () => import('../views/SzakmaInfoView.vue')
    
  },
  {
    path: '/VizsgaztatasiTeruletek',
    name: 'vizsgTeruletek',
    component: () => import('../views/VizsgaztatasiTeruletekView.vue')
    
  },
  {
    path: '/Vizsgazoknak',
    name: 'vizsgazoknak',
    component: () => import('../views/VizsgazoknakView.vue')
  },
  {
    path: '/Vizsgaztatoknak',
    name: 'vizsgaztatoknak',
    component: () => import('../views/VizsgaztatoknakView.vue')
  },
  {
    path: '/AddNews',
    name: 'addNews',
    component: () => import('../views/AddNewsView.vue')
  },
  {
    path: '/AddNewsList',
    name: 'addNewsList',
    component: () => import('../views/AddNewsListView.vue')
  },
  {
    path: '/Felhasznalok',
    name: 'felhasznalok',
    component: () => import('../views/FelhasznalokView.vue')
  },
  {
    path: '/Agazatok',
    name: 'agazatok',
    component: () => import('../views/AgazatokView.vue')
  },
  {
    path: '/Szakmak',
    name: 'szakmak',
    component: () => import('../views/SzakmakView.vue')
  },
  {
    path: '/AddSzakma',
    name: 'addSzakma',
    component: () => import('../views/AddSzakmaView.vue')
  },
  {
    path: '/AddSzabalyzat',
    name: 'addSzabalyzat',
    component: () => import('../views/AddSzabalyzatView.vue')
  },
  {
    path: '/AddTajekoztato',
    name: 'addTajekoztato',
    component: () => import('../views/AddTajekoztatoView.vue')
  },
  {
    path: '/Login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/Naptar',
    name: 'naptar',
    component: () => import('../views/NaptarView.vue')
  },
]

const router = createRouter({
  
  scrollBehavior(to, from, savedPosition) {
    if (to.fullPath.substring(0,11)=='/Szabalyzat')
    {
      var splitUrl1 = to.fullPath.split('/');
      var splitUrl2 = splitUrl1[1].split('?');
      var splitUrl3 = splitUrl2[1].split('=');
      if (splitUrl2[0]=="Szabalyzat" && splitUrl3.length==2) {
        router.push({ path: '/Szabalyzatok', hash: '?id='+splitUrl3[1] })
      }
    }
    if (to.fullPath.substring(0,12)=='/Tajekoztato')
    {
      splitUrl1 = to.fullPath.split('/');
      splitUrl2 = splitUrl1[1].split('?');
      splitUrl3 = splitUrl2[1].split('=');
      if (splitUrl2[0]=="Tajekoztato" && splitUrl3.length==2) {
        router.push({ path: '/Tajekoztatok', hash: '?id='+splitUrl3[1] })
      }
    }
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
    
    //this.$router.push({ path: '/SelectedNews', hash: '#top' })
  },
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router


