<template>omend
  <div id="" class="partnerek-tamogatok">
    <section id="elerhetoseg" class="section_contact"> 
      <div class="container">   
        <h2 class="text-center" style="font-size:3em;font-weight:bold;margin-top:-135px;margin-bottom:65px; color:white;"><br>{{ msg }}</h2>
        <hr class="alahuzas"/>
         <div class="row">
            <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
              <p class="nagy">A vizsgáztatás rendszerével, vizsgabiztosoknak jelentkezéssel továbbá a vizsgaközponttal való kapcsolatfelvétel céljából forduljon hozzánk bizalommal!</p>
            </div>
          </div>
          <div class="row ablak">
            <form :action="FORM_ENDPOINT" @submit="handleSubmit" method="POST" target="_blank" class="w-100 mx-auto mt-5">
            <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12 hatter">
              <br>
              <div class="row">
                <div class="col-xs-6 col-md-6 col-lg-6 col-xl-6">
                  
                  <div class="input-group mb-3">
                    <input type="text" name="name" placeholder="Név" class="form-control" required v-model="Nev" />
                  </div>
                  <div class="input-group mb-3">
                    <input type="email" name="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,63}$" placeholder="Email" class="form-control" required v-model="Email" />
                    <span class="validity vm"></span>
                  </div>
                  <div class="input-group mb-3">
                    <input type="tel" name="phone" pattern="[+]{1}[0-9]{10,14}" placeholder="Telefonszám: +36701234567" class="form-control" required v-model="TelefonSzam" />
                    <span class="validity vm"></span>
                  </div>
                </div>
                <div class="col-xs-6 col-md-6 col-lg-6 col-xl-6">
                  <div class="input-group mb-3">
                  <textarea  name="message" placeholder="Üzenet" class="form-control" required v-model="Uzenet" />
                </div>
              </div>
            </div>
              <div style="margin-left:10px">
                <input style="margin-left:0px;" type="checkbox" class="chb_adatk" @click='checkAdatK()' value="1" aria-invalid="false" />
                <span style="margin-left:5px; font-size:25px;" class="chb_adatk">Elolvastam és elfogadom az <a href="https://www.miskolci-szc.hu/uploads/documents/45_2020--sz--kancellari-es-foig-utasotas-adatvedelmi-szabalyzat.pdf">Adatkezelési tájékoztató</a>ban foglaltakat.</span>
              </div>
            <div>
              <div>
                <button id="kuldes" disabled=false type="submit" class="btn btn-default m-2 float-end">
                  ÜZENET KÜLDÉSE
                <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button>
               </div>
               <p>&nbsp;</p>
               <p>&nbsp;</p>
              <div  v-if="submitted" class="text-center mt-10">
                <h2 id="koszonjuk" class="text-3xl">Köszönjük!</h2>
                <h3 id="koszonjuk" class="text-2xl">Hamarosan megkeressük.</h3>
                <p></p>
                <br>
              </div>
            </div>
          </div>
          <p></p>
          <br>
          </form>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

export  default {
  data() {
    return {
      Nev:"",
      Email:"",
      TelefonSzam:"",
      Uzenet:"",
      submitted: false,
      FORM_ENDPOINT: "https://public.herotofu.com/v1/d8a4b930-40e0-11ee-b201-930d103a99ad",
    }
  },
  components: {
   
  },
  name: 'ElerhetoSeg',
  props: {
    msg: String
  },
   methods: {
    handleSubmit() {
      setTimeout(() => {
        this.submitted = true;
      }, 100);
    },
     checkAdatK: function(){
      var elem=document.getElementById("kuldes");
      elem.disabled=!elem.disabled;
    },
    refreshData() {
      
    },
    sendClick() {
      console.log("");
      
    },
  },
  mounted: function () {
    this.refreshData();
  },
  
}
</script>

<style scoped>
a{
  text-decoration: none;
}
.text-2xl {
  font-size: 2.25rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 2.875rem;
  line-height: 2.25rem;
}
#koszonjuk {
  color:white;
}
button{
  margin-top:10px;
}
button:hover{
  color:white!important;
  background-color: rgb(199, 240, 15)!important;
}
.btn-default {
  background-color: rgb(177,203,57);
  color: white;
  border-color: #adadad;
  border-radius: 3px;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active    {
  background-color: rgb(177,203,57);
  color: white;
  border-color: #adadad;
}
input:invalid+span.vm:after {
  position: absolute; content: '✖';
  margin-top:-15px;
  margin-left: -8px;
  color: #8b0000;
}

input:valid+span.vm:after {
  position: absolute;
  content: '✓';
  margin-top:-0px;
  margin-left: -0px;
  color: #009000;
}
.ablak{
  text-align: left;
}
.chb_adatk{
  width: 40px;
  height: 22px;
  position: relative;
  border-radius: 5px;
  font-size:22px;
}

.input-group
{
  margin-left: 15px;
  width: 95%;
}
input,textarea{
    border-radius:  10px;
    height: 4.6rem;
    font-size: 25px;
}
textarea{
  height: 10.3em;
}
.hatter{
  background-color: lightgray; opacity: .8;
  border-radius: 15px;

}
h2{
  letter-spacing: 2px!important;
}
#elerhetoseg{
  background-image: url('../assets/img/help-bg.jpg');
}
.section_contact{
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.section_contact h2{
  color: #fff;
}

.alahuzas{
  margin-top:-45px;
  margin-left: 70px;
  margin-right: 70px;
  margin-bottom: 35px;
  border-top: 2px solid rgb(43,89,148);
  height:3px;
  border-width:0;
  color:white;
  background-color:white;
  opacity: 1;
}
.nagy{
  font-size: 1.6rem;
  color:white;
  text-align: center!important;
  margin-left: 100px;
  margin-right: 100px;
}
.dolt{
  margin-top:-25px;
  font-size: 1.4rem;
  color:white;
  text-align: left!important;
  font-style: italic;
}
.normal{
  margin-top:-25px;
  font-size: 1.4rem;
  color:white;
  text-align: left!important;
}
.eltol{
  margin-top:12px;
}
button{
  margin-right: 30px;
}
</style>
