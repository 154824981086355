<template>
  <div id="" class="partnerek-tamogatok">
    <section id="elerhetoseg" class="section_contact">
      <div class="container">
        <h2
          class="text-center"
          style="
            font-size: 3em;
            font-weight: bold;
            margin-top: -135px;
            margin-bottom: 65px;
            color: white;
          "
        >
          <br />{{ msg }}
        </h2>
        <hr class="alahuzas" />
        <div class="row">
          <div class="col-xs-6 col-md-6 col-lg-6 col-xl-6">
            <div class="row">
              <div class="col-2 col-lg-1 px-0">
                <p><i class="fas fa-map-marker-alt"></i></p>
              </div>
              <div class="col-10 col-lg-11">
                <p class="nagy">3530 Miskolc, Soltész Nagy Kálmán utca 10.</p>
                <p class="dolt">Bejárat a Bajcsy-Zsilinsky utcai park felöl</p>
              </div>
            </div>
            <div class="row">
              <div class="col-2 col-lg-1 px-0">
                <p><i class="fas fa-phone-alt"></i></p>
              </div>
              <div class="col-10 col-lg-11" style="text-align: left">
                <a
                  class="nagy"
                  href="tel:+36705036166"
                  style="color: white;!important;"
                  >+36 70 503 6166</a
                >
              </div>
            </div>
            <div class="row">
              <div class="col-2 col-lg-1 px-0">
                <p><i class="fas fa-envelope-open-text"></i></p>
              </div>
              <div class="col-10 col-lg-11">
                <div class="col-md-10" style="text-align: left">
                  <a
                    class="nagy eltol"
                    href="mailto:vizsgakozpont@miskolci-szc.hu"
                    >vizsgakozpont@miskolci-szc.hu</a
                  >
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-2 col-lg-1 px-0">
                <p><i class="fas fa-user-alt"></i></p>
              </div>
              <div class="col-10 col-lg-11">
                <p class="nagy" style="font-weight: bold">ÜGYFÉLFOGADÁSI IDŐ</p>
                <p class="normal">H-CS: 8.00-15.30</p>
                <p class="normal">P: 8.00-13.00</p>
                <p>&nbsp;</p>
              </div>
            </div>
          </div>
          <div class="col-xs-6 col-md-6 col-lg-6 col-xl-6">
            <div class="map_container">
              <iframe
                  width="100%; border:0;"
                  height="400"
                  frameborder="0"
                  scrolling="no"
                  marginheight="0"
                  marginwidth="0"
                  src="https://www.openstreetmap.org/export/embed.html?bbox=20.79657%2C48.10252%2C20.79657%2C48.10252&layer=mapnik&marker=48.10247,%2020.79688#"
                ></iframe>
              <!--iframe
                style="width: 100%; border: 0"
                height="400"
                frameborder="0"
                allowfullscreen
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d666.1022530045706!2d20.79641329585918!3d48.10234515455193!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4740a002083fe6a1%3A0x3d4ba3ff12b98b52!2sMiskolc%2C%20Bajcsy-Zsilinszky%20utca%2C%203530!5e0!3m2!1shu!2shu!4v1646571571224!5m2!1shu!2shu"
              >
              </iframe-->
              <p></p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  components: {},
  name: "ElerhetoSeg",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
#elerhetoseg {
  background-image: url("../assets/img/eler-bg.jpg");
}
.section_contact {
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}

.section_contact h2 {
  color: #fff;
}

.alahuzas {
  margin-top: -45px;
  margin-left: 70px;
  margin-right: 70px;
  margin-bottom: 35px;
  border-top: 2px solid rgb(43, 89, 148);
  height: 3px;
  border-width: 0;
  color: white;
  background-color: white;
  opacity: 1;
}
.nagy {
  font-size: 1.7rem;
  color: white;
  text-align: left !important;
}
.dolt {
  margin-top: -25px;
  font-size: 1.4rem;
  color: white;
  text-align: left !important;
  font-style: italic;
}
.normal {
  margin-top: -25px;
  font-size: 1.4rem;
  color: white;
  text-align: left !important;
}
.eltol {
  margin-top: 12px;
}

i {
  font-size: 3rem;
  color: white;
  margin-top: 8px;
  margin-bottom: 30px;
}
</style>
