<template>
  <div class="megkozelites well-cont">
    <h1 style="margin-left: 20px;">{{ msg }}</h1>
    <div class="container theme-showcase" role="main">
      <div class="row">
        <!--div class="col-md-1 col-sm-1 col-lg-1 col-xl-1">
        </div-->
        <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
          <div class="p-1">
            <!--h1 style="font-size: 2.6em;">MEGKÖZELÍTÉS</h1-->
            <hr class="alahuzas"/>
            <div class="row eszkoz">
              <div class="col-xs-9 col-md-9 col-lg-9 col-xl-9">
                <div class="row">
                  <div class="col-2 col-lg-1 px-0">
                    <img src="../assets/img/bus.png" alt="">
                  </div>
                  <div class="col-10 col-lg-11">
                    <p class="nagy">AUTÓBUSSZAL</p>
                    <p class="normal"><span class="kor">&nbsp;1&nbsp;</span> <span class="kor">&nbsp;3&nbsp;</span> <span class="kor">&nbsp;4&nbsp;</span> <span class="kor">&nbsp;7&nbsp;</span> autóbuszokkal</p>
                    <p class="normal">Soltész Nagy Kálmán utca nevű megállóhely</p>
                  </div>
                </div>      
              </div>
              <div class="col-xs-3 col-md-3 col-lg-3 col-xl-3">
                <div class="map_container">
                  <a href="https://www.mvkzrt.hu/menetrend" target="_blank">
                    <button
                      type="button"
                      class="btn btn-default m-2 float-end"
                    >
                    MENETREND
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div class="row eszkoz">
              <div class="col-xs-9 col-md-9 col-lg-9 col-xl-9">
                <div class="row">
                  <div class="col-2 col-lg-1 px-0">
                    <img src="../assets/img/tram.png" alt="">
                  </div>
                  <div class="col-10 col-lg-11">
                    <p class="nagy">VILLAMOSSAL</p>
                    <p class="normal"><span class="kor">&nbsp;1&nbsp;</span> <span class="kor">&nbsp;2&nbsp;</span> villamosokkal</p>
                    <p class="normal">Soltész Nagy Kálmán utca nevű megállóhely</p>
                  </div>
                </div>
              </div>
              <div class="col-xs-3 col-md-3 col-lg-3 col-xl-3">
                <div class="map_container">
                  <a href="https://www.mvkzrt.hu/menetrend" target="_blank">
                    <button
                      type="button"
                      class="btn btn-default m-2 float-end"
                    >
                    MENETREND
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <div class="row eszkoz">
              <div class="col-xs-9 col-md-9 col-lg-9 col-xl-9">
                <div class="row">
                  <div class="col-2 col-lg-1 px-0">
                    <img src="../assets/img/car.png" alt="">
                  </div>
                  <div class="col-10 col-lg-11">
                    <p class="nagy">AUTÓVAL</p>
                    <p class="normal">Parkoló a Soltész Nagy Kálmán utca felöl <span class="dolt">(korlátozott számban díjmentesen)</span></p>
                    <p class="normal">Térítéses parkolás a Bajcsy-Zsilinszky utca felöl</p>
                  </div>
                </div>
              </div>
              <div class="col-xs-3 col-md-3 col-lg-3 col-xl-3">
                <div class="map_container">
                  <a href="https://varosgazda.hu/parkolas/parkolasi-zonak" target="_blank">
                    <button
                      type="button"
                      class="btn btn-default m-2 float-end"
                    >
                    PARKOLÁS&nbsp;&nbsp;&nbsp;&nbsp;
                    <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                    </button>
                  </a>
                </div>
              </div>
            </div>
            <br>
            <br>
          </div>          
        </div>  
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MegkozeliTes',
  props: {
    msg: String
  },
  methods: {
    refreshData() {
      
    },
    viewClick() {
    },
  },
  mounted: function () {
    this.refreshData();
  },
}
</script>

<style scoped>
.icon{
  font-size: 25px;
  color: rgb(153,181,4);
}
button{
  margin-top:10px;
}
button:hover{
  color:white!important;
  background-color: rgb(199, 240, 15)!important;
}
.btn-default {
  background-color: rgb(177,203,57);
  color: white;
  border-color: #adadad;
  border-radius: 3px;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active    {
   background-color: rgb(177,203,57);
  color: white;
  border-color: #adadad;
}
img{
  width: 100px;
}
.megkozelites{
  margin-top:-78px;
}
h1,h4{
  margin-left: 80px;
  margin-right: 80px;
  color:rgb(56,74,137);
}
h1{
  letter-spacing: 2px;
  font-size:3em;
  margin-top:-20px;
  font-weight: bold;
}
h4{
  font-size:1.7em;
  margin-bottom: 0px;
}
.alahuzas{
  margin-left: 70px;
  margin-right: 70px;
  border-top: 2px solid rgb(43,89,148);
  height:3px;
  border-width:0;
  color:rgb(43,89,148);
  background-color:rgb(43,89,148);
  opacity: 1;
}
a{
  text-decoration: none;
}
.front {
  z-index: 1;
}
.nagy{
  margin-left: 40px;
  font-size: 1.7rem;
  color:rgb(43,89,148);
  text-align: left!important;
  font-weight: bold;
}
.normal{
  margin-left: 40px;
  margin-top:-15px!important;
  font-size: 1.4rem;
  color:rgb(43,89,148);
  text-align: left!important;
}
.normal{
  margin-top:-25px;
  font-size: 1.4rem;
  color:rgb(43,89,148);
  text-align: left!important;
}
.eltol{
  margin-top:12px;
}
.kor{
  border:2px solid rgb(43,89,148);
  border-radius: 50%;
}
.eszkoz{
  margin-top:50px;
}
.dolt{
  font-style: italic;
  font-size: 90%;
}
</style>
