<template>
  <div class="app">
    <Header msg="A NAH által NAH-12-0043/2022 számon akkreditált vizsgaközpont (személytanúsító szervezet)"/>
    <menu-bar />
    <router-view/>
    <foote-r msg=""/>
  </div>
</template>

<script>
import Header from '@/components/Header.vue'
import MenuBar from '@/components/MenuBar.vue'
import FooteR from './components/FooteR.vue'
export default {
name: 'App',
  components: {
    Header,
    MenuBar,
    FooteR
  },
  setup() {   
  }
}
</script>

<style>

.routerLink{
     text-decoration: none;
 }
.nav-link:hover,.navbar-brand:hover {
    color: rgb(193,232,240) !important;
}


/*nav li:hover,
 .navbar-brand li:hover,
 nav li.router-link-active,
 nav li.router-link-exact-active {
   background-color: indianred;
   cursor: pointer;
 }*/
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#menu {
  margin-top: -43px;
}
.navbar-brand {
    color:white!important;
    font-size:24px !important;
    margin-left: 40px;
}

@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,700&subset=latin-ext');

@font-face {
  font-family: myriadlight;
  src: url(assets/fonts/myriad-pro-light.woff);
}

@font-face {
  font-family: myriadcond;
  src: url(assets/fonts/myriadpro-cond.woff);
}

@font-face {
  font-family: myriadpro;
  src: url(assets/fonts/myriadpro-regular.woff);
}

a:hover {
    text-decoration: none;
}
html body p, html body span, html body div {
    font-family: 'PT Sans', sans-serif;
}

body .navbar-inverse {
    background-color: #1962aa;
    border-color: #1962aa;
}

.navbar-inverse .navbar-nav>.active>a, .navbar-inverse .navbar-nav>.active>a:focus, .navbar-inverse .navbar-nav>.active>a:hover {
    background-color: #0d5db5;
}

.navbar-inverse .navbar-nav>li>a {
    color: #fff;
    text-transform: uppercase;
    padding: 15px 20px;
    font-size: 16px;
}

.navbar-inverse .navbar-nav>li>a:focus, .navbar-inverse .navbar-nav>li>a:hover {
    background-color: #0d5db5;
}

.telefon-top {
    background: url('assets/img/mobil.png') no-repeat 0 0;
    padding: 10px 0 10px 50px;
    margin: 18px 0 18px 35px;
}

.email-top {
    background: url('assets/img/email.png') no-repeat 0 0;
    padding: 10px 0 10px 50px;
    margin: 18px 0 18px 15px;
}
html body .ugyfelszolgalat-top {
    background: url('assets/img/call-center.png') no-repeat 0 0;
    padding: 10px 0 10px 50px;
    margin: 18px 0 18px 15px;
}

body .top-om {
    padding: 21px 0 21px 30px;
    margin: 0;
}
body .top-om .info-2 {
    color: #000;
}

.info-1 {
    font-size: 11px;
    color: #888888;
    margin: 0;
}
.info-2 {
    font-size: 14px;
    color: #000;
    margin: 0;
    text-align: left;
}
.social-top {
    margin-top: 20px;
}
.social-a {
    background: url('assets/img/Decor/social.png') no-repeat 0 0;
    height: 74px;
    width: 74px;
    display: inline-block;
    margin: 0 5px;
}
.social-a.ytb {
    background-position-x: -80px;
    width: 74px;
}

.top-info-box {
    position: fixed; 
    top: 0; right: 0; 
    left: 0; 
    width: 100%; 
    z-index: 1031; 
    height: 78px; 
    margin: 0 auto; 
    background-color: #fff;
}
.top-info {
    width: 1140px;
    height: 78px; 
    margin: 0 auto;
    position: relative;
}

body .navbar-fixed-top {
    top: 76px;
}

.top-container {
    margin-top: 125px;
}

.top-img {
    width: 100%;
    text-align: center;
}
.top-img img {
    width: 100%;
}

.intro {
    padding: 80px 0;
}

.main-title {
    margin: 0 0 30px 0;
    font-size: 30px;
    font-family: 'PT Sans', sans-serif;
}
span.green {
    color: #adc90a;
}

p.s-text {
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0;
}

img.infograf {
    width: 90%;
    margin: 50px auto 0 auto;
    display: block;
}

.well-cont {
    margin-top: 0px;
    
    background: url('assets/img/well-bg.jpg') no-repeat top center;
    padding: 80px 0;
    background-repeat: no-repeat;
    background-size: cover;
}

.eler-cont {
    margin-top: 0px;
    
    background: url('assets/img/eler-bg.jpg') no-repeat top center;
    padding: 80px 0;
    background-repeat:no-repeat;
    background-size: cover;
}

.news-cont {
    margin-top: 0px;
    
    background: url('assets/img/news-bg.jpg') no-repeat top center;
    padding: 80px 0;
    background-repeat:no-repeat;
    background-size: cover;
}
.news-cont .main-box-title {
    margin-top: 0;
    padding-top: 0;
}
.kepek-cont {
    margin-top: 100px;
    padding-bottom: 45px;
    
}
.hir-img {
    display: block;
    width: 100%;
    height: 190px;
    background-position: center;
    background-size: cover;
}

.kepzes-title, .kapcsolat-title {
    font-size: 30px;
    margin-top: 60px;
    margin-bottom: 20px;
}
.main-box-title {
    font-family: 'myriadlight';
    font-size: 45px;
    color: #eb8a00;
    display: inline-block;
    margin: 0 auto;
    padding: 10px 30px;
    margin-top: 30px;
    border-bottom: 0px solid rgb(23, 139, 0);
}


span.light-blue {
    color: #91cef0;
    margin-bottom: 5px;
}
span.light-green {
    color: #acca0c;
}
.left-border-kepzes, .left-border-kapcsolat {
    border-left: 2px solid #c2e2f6;
    padding-left: 15px;
    margin-bottom: 65px;
}
body .left-border-kapcsolat {
    border-color: #acca0c;
    margin-bottom: 15px;
    min-height: 120px;
}
.g-map {
    padding-left: 30px;
}
.text-bold {
    font-weight: bold;
}
.kapcsolat-cont {
    padding-bottom: 74px;
}
.kepzes-cont p {
    font-size: 17px;
    color: #888888;
}
.kapcsolat-cont p {
    font-size: 19px;
}
.mb-20 {
    margin-bottom: 20px;
}
.kapcsolat-info p {
    line-height: 23px;
}
.kapcsolat-info {
    margin-top: 80px;
}

span.blue {
    color: #0a509d;
}
span.blue-line {
    display: block;
    width: 10%;
    height: 2px;
    background-color: #0a509d;
    margin: 0 auto;
}

.main-news-cont {
    background-color: #fff;
    margin-top: 70px;
    height: 410px;
    border: 1px solid #c1e1f2;
}
.main-news-cont .main-news-title h3 {
    margin: 15px;
    color: #3271ac;
    font-family: 'myriadpro';
    font-size: 22px;
    height: 50px;
}
a.read-more-news {
    position: absolute;
    bottom: 15px;
    left: 15px;
}
p.main-news-desc {
    font-size: 18px;
    color: #888888;
    padding: 10px 15px;
    line-height: 20px;
    height: 86px;
}


.tartalom-cont a.read-more {
    background-position: 110px center;
    left: 25px;
}
a.more-news {
    border-radius: 5px;
    background-color: rgb(43,89,148);
    text-decoration: none;
    color: #fff;
    font-size: 22px;
    display: inline-block;
    padding: 0 30px;
    line-height: 44px;
    margin: 50px auto 0 auto;
    font-family: 'myriadlight';
}
a.more-news:hover {
   
    background-color: rgb(91, 151, 230);
    color:white;
}
.w-100 {
    width: 100%;
}

.kepzes-img {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
}
.kepzes-img span {
    font-size: 20px;
    display: inline-block;
    margin-top: 15px;
}
.kepzes-img img {
    border: 5px solid #0a509d;
}
.kepek-cont {
    background-color: #f0f6fa;
    padding-bottom: 75px;
}
.photos-cont {
    margin-top: 60px;
}
.gallery-img {
    width: 100%;
    height: 245px;
    background-position: center center;
    background-size: cover;
    position: relative;
}
.gallery-img.h-510 {
    height: 510px;
}
.mt-20 {
   margin-top: 20px; 
}
a.open-gallery {
    position: absolute;
    width: 100%;
    opacity: 0;
    transition: opacity 0.5s ease;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(15,67,124, .65);
}
.gallery-img:hover a.open-gallery {
    opacity: 1;
}
.open-gallery span.nagyito-img {
    background-color: #fff;
    width: 100px;
    height: 100px;
    text-align: center;
    border-radius: 50%;
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 40%;
    left: 0;
    right: 0;
    margin-top: -50px;
}
.open-gallery span.nagyito-img img {
    margin-top: 25px;
}
.open-gallery span.text {
    font-size: 20px;
    color: #FFF;
    position: absolute;
    top: 65%;
    display: block;
    text-align: center;
    left: 0;
    right: 0;
}

.news-single {
    margin-top: 50px;
}

footer {
    background-color: #339fd7;
    text-align: center;
    padding: 15px 0;
}
.footer-menu {
    margin-bottom: 15px;
}
footer .footer-menu a {
    color: #fff;
    font-size: 18px;
    display: inline-block;
    margin: 0 5px;
}
.copyright {
    font-size: 15px;
    color: #fff;
    margin: 15px 0 5px 0;
}

.basic-text {
    font-size: 18px;
    line-height: 20px;
    margin: 20px 0;
    
}
.kereso-form label {
    font-weight: normal;
}
.kereso-form h3 {
    border-bottom: 1px solid #888;
}

.gallery-title {
    position: absolute;
    font-size: 36px;
    color: #fff;
    top: 50%;
    left: 0;
    right: 0;
    margin-top: -18px;
}

.item-desc {
    display: none;
    padding: 10px;
    border: 1px solid #ddd;
    background-color: #fff;
}

@media screen and (max-width: 1140px) {
    body div.top-info {
        width: 100%;
    }
    .top-container {
        padding-top: 100px;
    }
}

@media screen and (max-width: 876px) {
    .social-top {
        display: none;
    }
    .top-container {
        padding-top: 120px;
    }
}

@media screen and (max-width: 776px) {
    .top-info-box {
        display: none;
    }
    body .navbar-fixed-top {
        top: 0px;
    }
}

@media screen and (max-width: 767px) {
    .top-container {
        padding-top: 0px;
    }
    body .g-map {
        margin-top: 15px;
    }
    body .left-border-kapcsolat {
        min-height: inherit;
    }
    body .kapcsolat-info {
        margin-top: 15px;
    }
}


/* Create four equal columns that floats next to eachother */
.column {
  float: left;
  width: 25%;
}

/* The Modal (background) */
.modal {
  display: none;
  position: fixed;
  z-index: 5000;
  padding-top: 50px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
}

/* Modal Content */
.modal-content {
  position: relative;
  background-color: #fefefe;
  margin: auto;
  padding: 0;
  width: 58%;
  max-width: 1200px;
}

/* The Close Button */
.close {
  color: white;
  position: absolute;
  top: 10px;
  right: 25px;
  font-size: 35px;
  font-weight: bold;
  opacity: .5;
}

.close:hover,
.close:focus {
  color: #999;
  text-decoration: none;
  cursor: pointer;
}

/* Hide the slides by default */
.mySlides {
  display: none;
}

/* Next & previous buttons */
.prev,
.next {
  cursor: pointer;
  position: absolute;
  top: 50%;
  width: auto;
  padding: 16px;
  margin-top: -50px;
  color: white;
  font-weight: bold;
  font-size: 20px;
  transition: 0.6s ease;
  border-radius: 0 3px 3px 0;
  user-select: none;
  -webkit-user-select: none;
}

/* Position the "next button" to the right */
.next {
  right: 0;
  border-radius: 3px 0 0 3px;
}

/* On hover, add a black background color with a little bit see-through */
.prev:hover,
.next:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

/* Number text (1/3 etc) */
.numbertext {
  color: #f2f2f2;
  font-size: 12px;
  padding: 8px 12px;
  position: absolute;
  top: 0;
}

/* Caption text */
.caption-container {
  text-align: center;
  background-color: black;
  padding: 2px 16px;
  color: white;
}

img.demo {
  opacity: 0.6;
}

.active,
.demo:hover {
  opacity: 1;
}

img.hover-shadow {
  transition: 0.3s;
}

.hover-shadow:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
@media screen and (max-width: 486px){
    body .top-container {
        padding-top: 35%;
    }
    .top-text-w, .top-text-w2 {
        color: #fff;
        z-index: 2000;
    }
}
@media screen and (max-width: 768px){
    .top-text-w, .top-text-w2 {
        color: #fff;
        z-index: 2000;
        top: 5px;
        left: 10px;
    }
    .top-text-w2 {
        top: 25px;
    }
}
.ugyf-cont:first-child {
	margin-top: 80px;
}
.ugyf-cont {
	background-color: #339fd7;
	padding: 10px;
	margin: 20px 0;
}
.ugyf-cont p {
	color: #fff;
	margin: 10px 0;
	font-size: 18px;
}
.ugyf-cont a {
	display: inline-block;
	background-color: #0a509d;
	color: #fff;
	font-size: 30px;
	padding: 8px 25px;
	width: 75%;
	margin: 10px 0;
	transition: background-color 0.5s ease;
}
.ugyf-cont a:hover {
	background-color: #032d5b;
}

h2.ugyfelszolg-title {
	font-size: 30px;
	margin: 100px 0 20px 0;
}
.ugyfelszolg-title span {
	color: #acca0c;
}
.btn.btn-ugyf {
	background-color: #339fd7;	
	border-color: #339fd7;
	color: #fff;
	text-transform: uppercase;
	border-radius: 0;
}
span.error {
	color: red;
}

a.sitelogo img {
    height: 62px;
    margin: 8px 0;
}

img.itmlogo {
    position: absolute;
    left: -154px;
    top: 0;
}
img.szechenyi-t {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 1050;
}


.main-box-top {
    font-family: 'myriadcond';
    color: #fff;
    padding: 10px 0;
    -webkit-box-shadow: 5px 5px 20px -5px rgba(92,92,92,1);
    -moz-box-shadow: 5px 5px 20px -5px rgba(92,92,92,1);
    box-shadow: 5px 5px 20px -5px rgba(92,92,92,1);
    margin-top: -35%;
    border-radius: 10px;
}
.main-box-top h3 {
    font-family: 'myriadpro';
    font-size: 30px;
        
}
.main-box-1 {
    background-color:#194189; 
}

.main-box-2 {
    background-color: #194189; 
}

html body .main-box-3 {
    background-color: rgb(177,203,57);
    padding-top: 7px;
}
.main-box-4 {
    background-color: rgb(177,203,57); 
}

.main-box-1 h3, .main-box-2 h3, .main-box-3 h3, .main-box-4 h3 {
    width: 100%;
    margin: 0 auto;
    font-size: 28px;
    text-transform: uppercase;
    padding: 10px 10px 18px 0;
    border-bottom: 1px solid white;
}

body .main-box-2 h3 {
    border-bottom: 1px solid white;
}

body .main-box-3 h3 {
    border-bottom: 1px solid #a7d9f5;
    padding-bottom: 7px;
    line-height: 28px;
}

body .main-box-4 h3 {
    border-bottom: 1px solid #a7d9f5;
}

.main-box-1 p, .main-box-2 p, .main-box-3 p, .main-box-4 p {
    font-size: 20px;
    margin: 15px 0;
    line-height: 22px;
}
.main-box-1 a, .main-box-2 a, .main-box-3 a, .main-box-4 a {
    display: inline-block;
    font-family: 'myriadpro';
    color: #5c5c5c;
    background-color: #fff;
    font-size: 20px;
    padding: 0 14px;
    line-height: 37px;
}
h3.b-title:before {
    position: absolute;
    width: 2px;
    display: block;
    content: " ";
    height: 38px;
    background-color: #1962aa;
    left: -18px;
    top: -3px;
}
h3.b-title {
    position: relative;
    font-family: 'myriadpro';
    font-size: 30px;
    color: #1962aa;
    font-weight: bold;
    padding-top: 0;
    margin-top: 0;
}
p.s-title {
    font-family: 'myriadlight';
    font-size: 45px;
    color: #2f2b2c;
}
div.p-1 {
    padding: 0 40px;
}

span.main-news-date {
    font-family: 'myriadcond';
    position: absolute; 
    text-align: center; 
    color: #fff; 
    font-size: 21px; 
    background-color: #125b9f; 
    width: 64px; 
    text-transform: uppercase;
    line-height: 22px; 
    padding: 7px 0; 
    border: 1px solid #fff;
    left: 15px; 
    top: -10px;
}
.aktualis-projektek h2.main-box-title {
    font-family: 'myriadlight';
    font-size: 45px;
    border-bottom: none;
    color: #000;
    display: block;
    margin: 0 0 20px 0;
    padding-top: 0;
}
.aktualis-projektek span.blue {
    color: #1783cb;
}
.aktualis-projektek .blue-line {
    background-color: #77b6e0;
    width: 245px;
}

.owl-nav span {
    display: none;
}

html body div .owl-carousel .owl-nav .owl-next {
    background-position: 21px center;
    right: -45px;
    left: auto;
}
.owl-theme .owl-nav {
    position: relative;
}

.aktualis-projektek .owl-carousel p {
    font-family: 'myriadpro';
    font-size: 18px;
    color: #0a509d;
    text-align: center;
    line-height: 18px;
    margin-top: 20px;
}
.aktualis-projektek .owl-carousel .owl-item img {
    width: 75%;
    display: inline-block;
}
.aktualis-projektek {
    padding: 80px 0;
}
.aktualis-projektek .owl-carousel {
    margin: 40px 0 10px 0;
}

.kerdese-van {
    background-color: #1962aa;
    color: #fff;
    padding: 80px 0;
}
.kerdese-van p.kerdes-cim {
    font-family: 'myriadlight';
    font-size: 45px;
    margin-top: 0;
    line-height: 46px;
    margin-bottom: 25px;
}
.kerdese-van p.kerdes-text {
    font-family: 'myriadlight';
    font-size: 18px;
}
.kerdese-van .feher-doboz {
    background: #fff;
    padding: 25px 15px;
}
.kerdese-van .feher-doboz p {
    font-family: 'myriadlight';
    color: #0a509d;
    font-size: 18px;
    line-height: 22px;
    margin: 18px 0;
}
.kerdese-van a.k-gomb {
    font-family: 'myriadpro';
    font-size: 26px;
    color: #fff;
    background-color: #125b9f;
    line-height: 46px;
    display: inline-block;
    padding: 0 15px;
}
.partnerek-tamogatok {
    background-color: #f7fafc;
    padding: 80px 0;
    margin: 0;
}
.partnerek-tamogatok h2.main-box-title {
    font-family: 'myriadlight';
    font-size: 45px;
    border-bottom: none;
    font-weight: 300;
    color: #000;
    display: block;
    margin: 0 0 20px 0;
    padding-top: 0;
}
.partnerek-tamogatok span.blue {
    color: #99b504;
}
.partnerek-tamogatok .blue-line {
    background-color: #99b504;
    width: 245px;
}
.partnerek-tamogatok .owl-carousel {
    margin: 90px 0 0 0;
}
.partnerek-tamogatok .owl-carousel .owl-item img {
    width: 75%;
    display: inline-block;
}
html body .partnerek-tamogatok .owl-carousel .owl-nav .owl-prev, html body .partnerek-tamogatok .owl-carousel .owl-nav .owl-next {
    bottom: 40px;
}

.kapcsolat-cont h2.main-box-title {
    font-family: 'myriadlight';
    font-size: 45px;
    border-bottom: none;
    font-weight: 300;
    color: #000;
    display: block;
    margin: 20px 0;
}
.kapcsolat-cont span.blue {
    color: #49a6da;
}
.kapcsolat-cont .blue-line {
    background-color: #49a6da;
    width: 245px;
}
.kapcsolat-cont {
    padding: 60px 0 120px 0;
}

.tartalom-cont {
    margin-top: 128px;
    padding-bottom: 50px;
}

.tartalom-cont h2.main-box-title {
    font-family: 'myriadlight';
    font-size: 45px;
    border-bottom: none;
    font-weight: 300;
    color: #000;
    display: block;
    margin: 60px 0 20px 0;
}
.tartalom-cont span.blue {
    color: #49a6da;
}
.tartalom-cont .blue-line {
    background-color: #49a6da;
    
    margin-bottom: 30px;
    width: 245px;
}

.top-img {
    padding-top: 35%;
    position: relative;
}

.top-img img {
    transition: opacity .75s ease-in;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
}
img.msi {
    opacity: 0;
}




img.hir-kep-lista {
    width: 25%;
    float: left;
}

a.hir-lista-cim h3 {
    margin: 10px 0;
}

div.h-c {
    float: left;
    width: 75%;
    padding: 0 35px 20px 35px;
    position: relative;
}
p.hir-lista-datum {
    font-family: 'myriadpro';
    color: grey;
    font-size: 18px;
}
div p.hir-lista-desc {
    font-family: 'myriadpro';
    margin: 0;
    padding: 0;
}
div.hir-lista-elem {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #0d5db5;
}
.row.elem-lista {
    padding: 60px 0;
}
p.kepzes-bevezeto {
    margin: 20px 0 50px 0;
    font-family: 'myriadpro';
}

@media only screen and (max-width: 1024px) {
    .owl-theme .owl-nav {
        display: none;
    }
    .container {
        width: 100%;
    }
    .main-box-top {
        margin-top: 0;
        box-shadow: none;
    }
    .szines-dobozok .col-sm-12 {
        padding: 0 !important;
    }
    .g-map {
        padding: 0;
    }
    .kerdese-van .feher-doboz {
        margin: 15px 0;
    }
}

img.szechenyi-t-bal {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
}
img.itmlogo2 {
    display: none;
}
@media only screen and (max-width: 768px) {

    .top-img {
        margin-top: 50px;
        
    }
    
}

@media only screen and (max-width: 1120px) {

    body .email-top {
        display: none;
    }
}

@media only screen and (max-width: 850px) {

body .telefon-top {
    display: none;
}
}


.img-responsive,
.thumbnail > img,
.thumbnail a > img,
.carousel-inner > .item > img,
.carousel-inner > .item > a > img {
  display: block;
  max-width: 100%;
  height: auto;
}
</style>
