<template>
  <div class="quick well-cont">
    <h1>{{ msg }}</h1>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm-12 col-md-1 front mb-3"></div>
        <div class="col-sm-12 col-md-4 front">
          <div class="main-box-top main-box-1 text-center">
            <h3 class="">Vizsgára<br />jelentkezőknek</h3>
            <p>
              Tanuló vagyok szeretnék<br />
              vizsgára jelentkezni
            </p>
            <router-link class="routerLink" to="/Naptar"
              ><a>TOVÁBB</a></router-link
            >
            <!--a href="/kepzes/kozepfoku-beiskolazas">Megtekintés</a-->
          </div>
        </div>
        <div class="col-sm-12 col-md-2 front mb-3"></div>
        <div class="col-sm-12 col-md-4 front">
          <div class="main-box-top main-box-2 text-center">
            <h3 class="">Vizsgáztatóknak<br />Szakértőknek</h3>
            <p>Jelentkezés vizsgáztatónak<br />Vizsgáztatók továbbképzése</p>
            <router-link class="routerLink" to="/Vizsgaztatoknak"
              ><a>TOVÁBB</a></router-link
            >
          </div>
        </div>
        <div class="col-sm-12 col-md-1 front"></div>
      </div>
    </div>
    <div class="container theme-showcase" role="main">
      <div class="row">
        <!--div class="col-md-1 col-sm-1 col-lg-1 col-xl-1">
        </div-->
        <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12">
          <div class="p-1">
            <h1
              style="
                margin-bottom: 0px;
                text-align: left;
                font-size: 3em;
                margin-top: 65px;
              "
            >
              Tisztelt Érdeklődő!
            </h1>
            <hr class="alahuzas" />
            <h4 class="s-text betuMeret" align="justify">
              Szeretettel köszöntjük Önt a Miskolci Független Vizsgaközpont
              honlapján.
            </h4>
            <h4 class="s-text betuMeret" align="justify">
              Vizsgaközpontunk 2020. július 1-jén jött létre az új szakképzési
              rendszer részeként. Célunk, hogy mind az oktatás, mind a gazdasági
              szereplők részéről felmerülő igényekre az Ipar 4.0 által
              támasztott kihívásoknak megfelelően, gyorsan és rugalmasan
              reagálva, szakszerű vizsgaszervezéssel és lebonyolítással
              járuljunk hozzá, és minél több jelentkező számára tegyük lehetővé
              a szakmai végzettség megszerzését.
            </h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "QuickMenu",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
@media screen and (min-width: 770px) {
  h4.betuMeret {
    font-size: 28px !important;
    text-align: justify;
    margin: 0px 10px 0px 10px;
  }
  .quick {
    margin-top: -60px;
  }
}

@media screen and (max-width: 770px) {
  h4.betuMeret {
    font-size: 18px !important;
    text-align: justify;
    margin: 0px 10px 0px 10px;
  }
  .quick {
    margin-top: -103px;
  }
}
h1,
h4 {
  margin-left: 80px;
  margin-right: 80px;
  color: rgb(56, 74, 137);
}

h1 {
  font-weight: bold;
}
h4 {
  font-size: 1.7em;
  margin-bottom: 0px;
  margin-left: 0px;
}
.alahuzas {
  margin-left: 70px;
  margin-right: 70px;
  border-top: 2px solid rgb(43, 89, 148);
  height: 3px;
  border-width: 0;
  color: rgb(43, 89, 148);
  background-color: rgb(43, 89, 148);
  opacity: 1;
}

a {
  text-decoration: none;
}
.front {
  z-index: 1;
}
</style>
