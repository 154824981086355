<template>
  <div class="slide" id="slide">
    <div id="carouselExampleSlidesOnly" class="carousel slide carousel-fade" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img src="../assets/img/vk1.jpg" class="d-block w-100">
        </div>
        <div class="carousel-item">
          <img src="../assets/img/vk2.jpg" class="d-block w-100">
        </div>
        <div class="carousel-item">
        <img src="../assets/img/vk3.jpg" class="d-block w-100">
        </div>
        <div class="carousel-item">
        <img src="../assets/img/vk4.jpg" class="d-block w-100">
        </div>
      </div>
    </div>
  </div>

</template>

<script>
export default {
  name: 'IntroductionSlide',
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#slide{
  padding-top: 132px;
}
</style>
