<template>
  <div class="footer">
    <h1>{{ msg }}</h1>
    <img src="" class="" />
    <p class="copyright text-center">Miskolci Független Vizsgaközpont</p>
    <img src="../assets/img/logoa.png" style="border: none;width: 150px;margin-top: 5px;" />
    <p class="text-center" style="margin: 15px 0">
      <a
        href="https://www.miskolci-szc.hu/oldal/kozerdeku-adatok"
        style="color: #fff; font-size: 20px"
        >Közérdekű adatok</a
      >
      |
      <a
        href="https://www.miskolci-szc.hu/uploads/documents/45_2020--sz--kancellari-es-foig-utasotas-adatvedelmi-szabalyzat.pdf"
        style="color: #fff; font-size: 20px"
        >Adatkezelési irányelvek</a
      >
      |
    </p>
    <p class="copyright text-center">
      Minden jog fenntartva: Miskolci Független Vizsgaközpont 3526 Miskolc,
      Széchenyi István utca 103. Telefon:
      <a href="tel:+36705036166" style="color: white">+36 70 503 6166</a>
      E-mail:
      <a href="mailto:vizsgakozpont@miskolci-szc.hu" style="color: white"
        >vizsgakozpont@miskolci-szc.hu</a
      >
    </p>
  </div>
</template>

<script>
export default {
  name: "FooteR",
  props: {
    msg: String,
  },
};
</script>

<style scoped>
.footer {
  margin-top: -76px;
  /*background-color: rgb(13,202,240);*/
  /*background-color:  rgb( 70,184,210);*/
  background-color: #194189;
}
.copyright {
  font-size: 1.5rem;
}
</style>
