import { createStore } from "vuex";

export default createStore({
  state: {
    uId: "o",
    FNev: "",
    JelentkezesiLapFajlNeve: "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Documents/Egyeb/JelentkezesiLapKapesitoVizsgara_v04.docx",
    MinosegpolitikaFajlNeve: "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Documents/Egyeb/mp_ii_02.pdf",
    IranyitasiRendszerFajlNeve: "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Documents/Szabalyzatok/ikk_II_02.pdf",
    FelmentesiKerelemFajlNeve: "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Documents/Egyeb/felmentesi-meltanyossagi_kerelem.docx",
    szakmakBeolvasasa: true,
    szabalyzatokBeolvasasa: true,
    tajekoztatokBeolvasasa: true,
    agazatokBeolvasasa: true,
    hirekBeolvasasa: true,
    szakmak: [],
    agazatok: [],
    hirek: [],
    szabalyzatok: [],
    tajekoztatok: [],
    lastReadNewsId: 0,
    allNewsReaded: false,
    maxItem: 6,
    hirSzama: 0,
    szakmaSzama: 0,
    /*API_URL: "https://localhost:49175/",
    PHOTO_URL: "https://localhost:49175",
    SzakmaPhotoUrl: "https://localhost:49175/Img/Szakmak/",
    NewsPhotoUrl: "https://localhost:49175/Img/News/",
    SzabalyzatokUrl: "https://localhost:49175/Documents/Szabalyzatok/",*/
    //API_URL: "https://vizsgakozpontbackend1.azurewebsites.net/",
    //PHOTO_URL: "https://vizsgakozpontbackend1.azurewebsites.net/",
    API_URL: "https://vkp.regoba.com/",
    PHOTO_URL: "https://vkp.regoba.com/",
    //API_URL: "https://vizsgakozpont.kkszki-dev.hu/",
    //PHOTO_URL: "https://vizsgakozpont.kkszki-dev.hu/",
    //SzakmaPhotoUrl: "https://vizsgakozpontbackend1.azurewebsites.net/Img/Szakmak/",
    SzakmaPhotoUrl: "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Img/Szakmak/",
    //NewsPhotoUrl: "https://vizsgakozpontbackend1.azurewebsites.net/Img/News/",
    NewsPhotoUrl: "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Img/News/",
    //SzabalyzatokUrl: "https://vizsgakozpontbackend1.azurewebsites.net/Documents/Szabalyzatok/",
    SzabalyzatokUrl:
      "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Documents/Szabalyzatok/",
    //TajekoztatokUrl: "https://vizsgakozpontbackend1.azurewebsites.net/Documents/Tajekoztatok/",
    TajekoztatokUrl:
      "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Documents/Tajekoztatok/",
    VizsgaztatoknakUrl:
      "https://fajlok.miskolci-szc.hu/Vizsgakozpont/Documents/Vizsgaztatoknak/",
    agazatSzama: 0,
    szabalyzatSzama: 0,
    tajekoztatoSzama: 0,
    szakmaNeve: "",
    description: ``,
    newsTitle: "",
    newsDescription: "",
    newsShortDescription: "",
    newsCreationDate: "",
    selectedNewsImagaName: "default.jpg",
    editorConfig: {
      removePlugins: ["MediaEmbedToolbar"],
      toolbar: {
        viewportTopOffset: 0,
        shouldNotGroupWhenFull: true,
        items: [
          "heading",
          "|",
          "fontfamily",
          "fontsize",
          "fontColor",
          "fontBackgroundColor",
          "|",
          "alignment",
          "|",
          "subscript",
          "superscript",
          "|",
          "code",
          "codeBlock",
          "htmlEmbed",
          "|",
          "bold",
          "italic",
          "underLine",
          "strikeThrough",
          "|",
          "link",
          "uploadImage",
          "mediaEmbed",
          "|",
          "blockQuote",
          "horizontalLine",
          "|",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "|",
          "insertTable",
          "|",
          "outdent",
          "indent",
          "|",
          "undo",
          "redo",
          "|",
        ],
      },
      heading: {
        options: [
          {
            model: "paragraph",
            title: "Paragraph",
            class: "ck-heading_paragraph",
          },
          {
            model: "heading1",
            view: "h1",
            title: "Heading 1",
            class: "ck-heading_heading1",
          },
          {
            model: "heading2",
            view: "h2",
            title: "Heading 2",
            class: "ck-heading_heading2",
          },
          {
            model: "heading3",
            view: "h3",
            title: "Heading 3",
            class: "ck-heading_heading3",
          },
          {
            model: "heading4",
            view: "h4",
            title: "Heading 4",
            class: "ck-heading_heading4",
          },
          {
            model: "heading5",
            view: "h5",
            title: "Heading 5",
            class: "ck-heading_heading5",
          },
          {
            model: "heading6",
            view: "h6",
            title: "Heading 6",
            class: "ck-heading_heading6",
          },
        ],
      },

      table: {
        contentToolbar: [
          "tableRow",
          "tableColumn",
          "mergeTableCells",
          "tableCellProperties",
          "tableProperties",
        ],
      },

      // Run the editor with the Hungarian UI.
      language: "hu",
      placeholder: "Hír szövege (kötelező)",
    },
    image: {
      toolbar: [
        "imageStyle:alignLeft",
        "imageStyle:alignCenter",
        "imageStyle:alignRight",
        "|",
        "resizeImage",
        "|",
        "imageTextAlternative",
      ],
      styles: ["alignLeft", "alignCenter", "alignRight"],
      resizeOptions: [
        {
          name: "resizeImage:original",
          label: "Original",
          value: null,
        },
        {
          name: "resizeImage:25",
          label: "25%",
          value: "25",
        },
        {
          name: "resizeImage:50",
          label: "50%",
          value: "50",
        },
        {
          name: "resizeImage:75",
          label: "75%",
          value: "75",
        },
      ],
    },
  },
  getters: {
    getuId: (state) => {
      return state.uId;
    },
  },
  mutations: {},
  actions: {},
  modules: {},
});
