<template>
  <div class="home">
    <introduction-slide/>
    <quick-menu msg=""/>
    <news msg="HÍREK / FELHÍVÁSOK"/>
    <vizsgazoknak  msg="A következő ágazatokon szervezünk vizsgákat"/>
    <elerhetoseg msg="ELÉRHETŐSÉG"/>
    <megkozelites msg="MEGKÖZELÍTÉS"/>
    <help msg="MIBEN SEGÍTHETÜNK?"/>
    <partners msg="AJÁNLOTT HONLAPOK"/>
  </div>
</template>

<script>
import IntroductionSlide from '@/components/IntroductionSlide.vue'
import QuickMenu from '@/components/QuickMenu.vue'
import News from '@/components/NewsHeadLine.vue'
import Vizsgazoknak from '@/components/Vizsgazoknak.vue'
import Partners from '@/components/Partners.vue'
import Elerhetoseg from '@/components/Elerhetoseg.vue'
import Megkozelites from '@/components/Megkozelites.vue'
import Help from '@/components/Help'

export default {
name: 'HomeView',
  components: {
    IntroductionSlide,
    QuickMenu,
    News,
    Vizsgazoknak,
    Partners,
    Elerhetoseg,
    Megkozelites,
    Help,
  },
  setup() {
    
  },
}
</script>
