<template>
  <div class="header">
    <div class="fixed-top" style="background-color: white; height: 97px">
      <h4>Miskolci Független Vizsgaközpont</h4>
      <h6>{{ msg }}</h6>
    </div>
    <div class="container">
      <div class="top-info-box">
        <div class="top-info">
          <!--img src="../assets/img/itmlogo.png" class="itmlogo" alt="ITM" /-->
          <div class="float-start">
            <a
              class="sitelogo"
              href="https://vizsgakozpont.miskolci-szc.hu/"
              target="_blank"
              style="border: none"
              ><img src="../assets/img/logo.png" style="border: none"
            /></a>
          </div>
          <div class="telefon-top float-start">
            <a class="info-2" href="tel:+36705036166">+36 70 503 6166</a>
          </div>
          <div class="email-top float-start">
            <a class="info-2" href="mailto:vizsgakozpont@miskolci-szc.hu" style="font-size:16px;"
              >vizsgakozpont@miskolci-szc.hu</a
            >
          </div>
          <div class="float-start top-om ugyfelszolgalat-top">
            <a
              class="info-1"
              style="font-size: 14px; color: black;font-size:16px;" 
              href="https://www.nah.gov.hu/hu/szervezet-kereses?search=Miskolci%20F%C3%BCggetlen%20Vizsgak%C3%B6zpont&valid=true&suspended=true&withdrawn=false&category_slug="
              target="_blank"
              >A NAH által NAH-12-0043/2022 számon akkreditált vizsgaközpont</a
            >
            <p
              class="info-2"
              style="font-size: 14px; color: black;"
              href="https://www.nah.gov.hu/hu/szervezet-kereses?search=Miskolci%20F%C3%BCggetlen%20Vizsgak%C3%B6zpont&valid=true&suspended=true&withdrawn=false&category_slug="
              target="_blank"
            >
              (személytanúsító szervezet)
            </p>
          </div>
          <!--div class="email-top float-start ugyfelszolgalat-top">
            <p class="info-2">ugyfelszolgalat@miskolci-szc.hu</p>
          </div>
          <div class="float-start top-om">
          <p class="info-1" style="font-size: 12px;"><b>OM azonosító</b></p>
            <p class="info-2" style="font-size: 12px;"><b>203060</b></p>
          </div>
          <div class="float-start top-om">
            <p class="info-1" style="font-size: 12px;"><b>Felnőttképzési engedélyszám:</b></p>
            <p class="info-2" style="font-size: 12px;"><b>E-001290/2015</b></p>
          </div-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeaderLine",
  props: {
    msg: String,
  },
};
</script>
<style scoped>
h2 {
  font-size: 200%;
}
</style>
