<template>
  <div id="news" class="news-cont">
    <div class="container theme-showcase text-center">
      <h2
        class="text-center"
        style="font-weight: bold; color: rgb(43, 89, 148)"
      >
        {{ msg }}
      </h2>
      <hr class="alahuzas" />
      <!--div
        class="modal fade"
        id="exampleModal"
        tabindex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-xl modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title" id="exampleModalLabel">
                {{
                  this.$store.state.newsCreationDate
                    .toString()
                    .substring(0, 10) +
                  ".&nbsp;|&nbsp;" +
                  this.$store.state.newsShortDescription
                    .toString()
                    .substring(0, 100)
                }}
              </h5>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body">
              <div class="ck-content">
                <div class="input-group mb-3">
                  <div class="app selectedNews container">
                    <h1 class="icon">
                      <i class="fa fa-newspaper"></i>
                    </h1>
                    <br />
                    <div class="row">
                      <div class="col-lg-4">
                        <img
                          class="card-img-top img-thumbnail"
                          style="width: 95%"
                          :src="
                            this.$store.state.NewsPhotoUrl +
                            this.$store.state.selectedNewsImagaName
                          "
                          alt=""
                        />
                      </div>
                      <div class="col-lg-8">
                        <h2>
                          {{
                            this.$store.state.newsTitle
                              .toString()
                              .substring(0, 100)
                          }}
                        </h2>
                      </div>
                    </div>
                    <p></p>
                    <hr />
                    <br />
                    <div class="container">
                      <div class="row justify-content-center">
                        <div class="col-lg-10 pb-2">
                          <div class="ql-editor">
                            <div id="description"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div-->

      <div class="container">
        <div class="justify-content-center row">
          <div
            v-for="hir in this.$store.state.hirek"
            :key="hir.Id"
            class="col-sm-12 col-md-6 col-lg-4 col-xl-4"
          >
            <div
              class="card border-light mb-3"
              style="max-width: 19.4rem; min-width: 19.4rem; height: 28rem"
            >
              <div class="card-body">
                <img
                  v-if="hir.ImageLink != ''"
                  class="card-img-top"
                  :src="this.$store.state.NewsPhotoUrl + hir.ImageLink"
                  alt=""
                />
                <img
                  v-else
                  class="card-img-top"
                  :src="this.$store.state.NewsPhotoUrl + 'default.jpg'"
                  alt=""
                />
                <div>
                  <p>
                    <i class="far fa-calendar-alt icon"></i>&nbsp;
                    {{ hir.CreationDate.toString().substring(0, 10) }}
                  </p>
                </div>
                <hr />
                <h5 v-if="hir.Title.length < 60" class="card-title">
                  {{ hir.Title }}
                </h5>
                <h5 v-else class="card-title">
                  {{ hir.Title.substring(0, 55) + "..." }}
                </h5>
                <p class="short" v-if="hir.ShortDescription.length < 60">
                  {{ hir.ShortDescription }}
                </p>
                <p class="short" v-else>
                  {{ hir.ShortDescription.substring(0, 70) + "..." }}
                </p>
              </div>
              <div class="card-footer" style="margin-top: -35px">
                <div class="col-md-12 text-center">
                  <router-link
                    class="routerLink btn btn-default m-2 float-start"
                    :to="'/News?id=' + hir.Id.toString()"
                  >
                    Tovább<i
                      class="fa fa-angle-double-right"
                      aria-hidden="true"
                    ></i>
                  </router-link>
                </div>
                <!--button
                  type="button"
                  class="btn btn-default m-2 float-start"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModal"
                  @click="
                    viewClick(
                      hir.Id,
                      hir.Title,
                      hir.ShortDescription,
                      hir.ImageLink,
                      hir.CreationDate,
                      'Hir beolvasása...'
                    )
                  "
                >
                  Tovább
                  <i class="fa fa-angle-double-right" aria-hidden="true"></i>
                </button-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12 text-center">
        <router-link class="routerLink" to="/AllNews">
          <a href="/hirek" class="more-news">Tovább az összes hírhez</a>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
//import ClassicEditor from "@ckeditor/ckeditor5-build-classic/build/ckeditor";

export default {
  name: "NewS",
  props: {
    msg: String,
  },
  data() {
    return {
      //editor: ClassicEditor,
      modalTitle: "",
    };
  },
  methods: {
    refreshData() {
      if (this.$store.state.hirekBeolvasasa) {
        axios
          .get(
            this.$store.state.API_URL + "News?db=" + this.$store.state.maxItem
          )
          .then((response) => {
            this.$store.state.hirek = response.data;
            this.$store.state.hirekBeolvasasa = false;
            this.$store.state.lastReadNewsId =
              this.$store.state.hirek[this.$store.state.hirek.length - 1].Id;
            //console.log(this.$store.state.lastReadNewsId);
          });
      }
    },
    viewClick(id, title, shotrDescription, image, date, Description) {
      this.$store.state.newsTitle = title;
      this.$store.state.newsCreationDate = date;
      this.$store.state.newsShortDescription = shotrDescription + "...";
      this.$store.state.newsDescription = Description;
      if (image == "") {
        this.$store.state.selectedNewsImagaName = "default.jpg";
      } else {
        this.$store.state.selectedNewsImagaName = image;
      }
      axios
        .get(this.$store.state.API_URL + "News/" + id.toString())
        .then((response) => {
          this.$store.state.newsDescription = response.data[0].Description;
          document.getElementById("description").innerHTML =
            this.$store.state.newsDescription +
            "<style>p:first-letter {margin-left:5px; font-size: 1.1em;}</style>";
        });

      //this.$router.push({ path: '/SelectedNews', hash: '#top' })
    },
  },
  mounted: function () {
    this.refreshData();
  },
};
</script>

<style scoped>
.modal {
  background-image: url("../assets/img/help-bg.jpg");
  background-size: cover;
  /*background-color: rgba(43,89,148,0.95);*/
  /*background-color: rgba(0,0,0,0.75);*/
}

h2 {
  letter-spacing: 2px !important;
}
a:hover {
  background-color: rgb(69, 134, 219);
  color: white;
}
.alahuzas {
  margin-top: 0px;
  margin-left: 70px;
  margin-right: 70px;
  border-top: 2px solid rgb(43, 89, 148);
  height: 3px;
  border-width: 0;
  color: rgb(43, 89, 148);
  background-color: rgb(43, 89, 148);
  opacity: 1;
}
.card {
  border-radius: 15px;
}
img {
  width: 280px;
  height: 160px;
  border-radius: 10px;
}
.short {
  color: rgb(176, 178, 178);
}
button {
  margin-top: 10px;
}
button:hover {
  color: white !important;
  background-color: rgb(199, 240, 15) !important;
}
.btn-default {
  background-color: rgb(177, 203, 57);
  color: white;
  border-color: #adadad;
  border-radius: 3px;
}
.btn-default:hover,
.btn-default:focus,
.btn-default:active {
  background-color: rgb(177, 203, 57);
  color: white;
  border-color: #adadad;
}

h2:first-letter {
  margin-left: 20px;
}
h5 {
  font-weight: bold;
  text-align: left;
  color: rgb(56, 74, 137);
}
.news-cont {
  margin-top: 0px;
}
.card-footer {
  background: transparent;
  border-top: 0px;
}
.card {
  margin-bottom: 20px;
  margin-right: auto;
  margin-left: auto;
}
h2 {
  margin-bottom: 20px;
  font-size: 48px;
  text-align: left;
  color: rgb(13, 132, 230);
}
.icon {
  font-size: 25px;
  color: rgb(153, 181, 4);
}
p,
h4 {
  text-align: left;
}
p {
  padding: 5px;
  font-weight: bold;
  color: rgb(177, 203, 57);
}
hr {
  margin-top: -17px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 1px;
}
#icon {
  text-align: left;
  font-size: 20px;
  color: rgb(153, 181, 4);
  margin-top: 0px;
}
a {
  text-align: bottom;
  margin-bottom: 10px;
}
</style>
