<template>
  <div id="" class="partnerek-tamogatok well-cont">
    <div class="container theme-showcase">
      <h2 class="text-center" style="font-size:3em;font-weight:bold;margin-bottom:65px; color:rgb(43,89,148);">{{ msg }}</h2>
      <hr class="alahuzas"/>
        <Carousel :autoplay="3000" :wrap-around="true" :settings='settings' :breakpoints='breakpoints'>
          <Slide v-for="slide in imgslider" :key="slide">
            <div class="carousel__item">
              <a :href="slide.link" target="_blank" :title="slide.title" :alt="slide.title">
                <img class="imageSlider" :src="slide.name" />
              </a>
            </div>
          </Slide>
          <!--template #addons>
            <Navigation />
            <Pagination />
          </template-->
        </Carousel>
        <br>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from 'vue3-carousel';
//import { Carousel, Navigation, Pagination, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

export  default {
  data() {
    return {
      imgslider:[
        {id:1, name: require('@/assets/img/ajanlotthonlap-1.png'), link: "https://www.miskolci-szc.hu/", title:"Miskolci Szakképzési Centrum"},
        {id:2, name: require('@/assets/img/ajanlotthonlap-2.png'), link: "https://kormany.hu/kulturalis-es-innovacios-miniszterium", title:"Kulturális és Innovációs Minisztérium"},
        {id:3, name: require('@/assets/img/ajanlotthonlap-3.png'), link: "https://www.ikk.hu/", title:"Innovatív Képzéstámogató Központ"},
        {id:4, name: require('@/assets/img/ajanlotthonlap-4.png'), link: "https://www.nive.hu/", title:"Nemzeti Szakképzési és Felnőttképzési Hivatal"},
        {id:5, name: require('@/assets/img/ajanlotthonlap-5.png'), link: "https://bokik.hu/", title:"Borsod-Abaúj-Zemplén Megyei Kereskedelmi és Iparkamara"},
      ],
      settings: {
    itemsToShow: 1,
    snapAlign: 'center',
    },
    // breakpoints are mobile first
    // any settings not specified will fallback to the carousel settings
    breakpoints: {
      // 700px and up
      800: {
        itemsToShow: 2,
        snapAlign: 'start',
      },
      // 1024 and up
      1024: {
        itemsToShow: 3,
        snapAlign: 'start',
      },
    },
    }
  },
  components: {
    //Pagination,
    Carousel,
    Slide,
    //Navigation,
  },
  name: 'PartnerS',
  props: {
    msg: String
  }
}
</script>

<style scoped>
h2{
  letter-spacing: 3px!important;
}
.alahuzas{
  margin-top:-45px;
  margin-left: 70px;
  margin-right: 70px;
  margin-bottom: 35px;
  border-top: 2px solid rgb(43,89,148);
  height:3px;
  border-width:0;
  color:rgb(43,89,148);
  background-color:rgb(43,89,148);
  opacity: 1;
}
.carousel__slide > .carousel__item {
  transform: scale(.9);
  opacity: 0.5;
  transition: 0.5s;
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: scale(0.9) translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.9) translate(10px);
}
.carousel__slide--active > .carousel__item {
  transform: scale(1);
}
</style>
