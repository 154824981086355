<template>
  <div id="vizsgazoknak" class="well-cont">
    <div class="container-fluid align-items-center justify-content-center">
      <div class="row">
        <h2>{{ msg }}</h2>
        <div class="col-xs-12 col-md-12 col-lg-12 col-xl-12">
          <hr class="alahuzas" />
        </div>
        <div class="col-xs-1 col-md-1 col-lg-1 col-xl-1"></div>

        <div class="col-xs-10 col-md-10 col-lg-10 col-xl-10">
          <span
            v-for="agazat in this.$store.state.agazatok"
            :key="agazat.agazatNev"
            style="display: inline-flex; gap: 5px; align-items: center"
          >
            <span
              v-if="agazat.Aktiv == true"
              class="hexagon-item"
              style="left: 0px; margin-bottom: 34px"
            >
              <router-link
                class="routerLink"
                :to="'/AgazatInfo?id=' + agazat.Id.toString()"
              >
                <a
                  class="hex-content"
                  @click="
                    viewClick(
                      agazat.Id,
                      agazat.An11 +
                        ' ' +
                        agazat.An12 +
                        ' ' +
                        agazat.An21 +
                        ' ' +
                        agazat.An22,
                      agazat.IconName
                    )
                  "
                >
                  <span class="hex-content-inner">
                    <span class="icon">
                      <i :class="agazat.IconName"></i>
                    </span>
                    <span class="title szoveg"
                      >{{ agazat.An11 }}&nbsp;{{ agazat.An12 }}</span
                    >
                    <span class="title"
                      >{{ agazat.An21 }}&nbsp;{{ agazat.An22 }}</span
                    >
                    <span
                      v-if="agazat.number < '10'"
                      class="title"
                      style="
                        position: absolute;
                        margin-left: -10px;
                        margin-top: 10px;
                      "
                      >&nbsp;{{ agazat.number }}.</span
                    >
                    <span
                      v-else
                      class="title"
                      style="position: relative; margin-top: 0px"
                      >{{ agazat.Id }}.</span
                    >
                  </span>

                  <svg
                    style="margin-left: -18px; margin-top: -6px"
                    width="208px"
                    height="208px"
                    viewBox="0 0 64 64"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    aria-hidden="true"
                    role="img"
                    class="iconify iconify--emojione"
                    preserveAspectRatio="xMidYMid meet"
                  >
                    <circle class="szegely" cx="32" cy="32" r="30"></circle>
                    <circle class="kor" cx="32" cy="32" r="28"></circle>
                  </svg>
                </a>
              </router-link>
            </span>
          </span>
        </div>
      </div>
      <div class="col-xs-1 col-md-1 col-lg-1 col-xl-1"></div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "VizsgazoknaK",
  props: {
    msg: String,
  },
  data() {
    return {
      maxItem: 6,
    };
  },
  methods: {
    refreshData() {
      if (this.$store.state.agazatokBeolvasasa) {
        axios.get(this.$store.state.API_URL + "Agazat").then((response) => {
          this.$store.state.agazatok = response.data;
        });
        this.$store.state.agazatokBeolvasasa = false;
      }
    },
    viewClick(number, name, icon) {
      if (number != this.$store.state.agazatSzama) {
        this.$store.state.agazatSzama = number;
        this.$store.state.szakmakBeolvasasa = true;
        this.$store.state.szakmak = [];
      }

      this.$store.state.agazatNeve = name;
      this.$store.state.iconName = icon;
      //this.$store.state.szakmakBeolvasasa=true;

      //console.log(this.$store.state.agazatSzama);
    },
  },
  mounted: function () {
    this.refreshData();
  },
};
</script>

<style scoped>
.kor {
  fill: rgb(203, 203, 203);
}
.szegely {
  fill: white;
}
span {
  font-weight: bold;
}
.alahuzas {
  margin-left: 70px;
  margin-right: 70px;
  margin-top: -50px;
  border-top: 2px solid rgb(43, 89, 148);
  height: 3px;
  border-width: 0;
  color: rgb(43, 89, 148);
  background-color: rgb(43, 89, 148);
  opacity: 1;
}
.news-cont {
  margin-top: -68px;
}
h2 {
  margin-top: -20px;
  letter-spacing: 2px;
  margin-bottom: 65px;
  text-align: center !important;
  font-size: 3em;
  font-weight: bold;
  color: rgb(43, 89, 148);
}

.hexagon-item {
  cursor: pointer;
  width: 185px;
  height: 173.20508px;
  float: left;
  margin-right: -30px;
  transform: rotate(30deg);
}

.hexagon-item:hover .hex-content svg .kor {
  transform: scale(0.98) rotate(-30deg);
  cx: 12px;
  cy: 44.6px;
  r: 31px;
  fill: rgb(230, 230, 230);
}

.hexagon-item:hover .hex-content svg .szegely {
  transform: scale(0.97);
  cx: 33px;
  cy: 33px;
  r: 33px;
  fill: lightgray;
}

.hex-content {
  color: #fff;
  display: block;
  height: 180px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  transform: rotate(-30deg);
  width: 156px;
}
.hex-content .hex-content-inner {
  left: 50%;
  margin: -3px 0 0 2px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
.hex-content .icon {
  display: block;
  font-size: 50px;
  line-height: 30px;
  margin-bottom: 17px;
}
.hex-content .title {
  display: block;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  line-height: 24px;
  text-transform: uppercase;
}
.hex-content svg {
  left: -7px;
  position: absolute;
  top: -12px;
  transform: scale(0.87);
  z-index: -1;
  transition: all 0.3s cubic-bezier(0.165, 0.84, 0.44, 1) 0s;
}
.hex-content:hover {
  color: rgb(43, 89, 148);
}

span {
  margin-top: -4px;
}
.szoveg {
  margin-top: 24px;
}

.hexagon-item:first-child {
  margin-left: 0;
}

.pt-table.desktop-768 .pt-tablecell {
  padding-bottom: 110px;
  padding-top: 60px;
}

.hexagon-item:hover .icon i {
  color: rgb(176, 203, 54);
  transition: 1.6s;
}

.hexagon-item:hover .title {
  -webkit-animation: focus-in-contract 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: focus-in-contract 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  font-weight: bolder;
}

@keyframes focus-in-contract {
  0% {
    letter-spacing: 1em;
    -webkit-filter: blur(12px);
    filter: blur(12px);
    opacity: 0;
  }
  100% {
    -webkit-filter: blur(0px);
    filter: blur(0px);
    opacity: 1;
  }
}
@media only screen and (max-width: 720px) {
  .page-home {
    margin-top: -70px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  .hexagon-item {
    float: none;
    margin: 0 auto 50px;
  }
  .hexagon-item:first-child {
    margin-left: auto;
  }

}
</style>
