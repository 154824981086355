import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/css/bootstrap.min.css"
import CKEditor from '@ckeditor/ckeditor5-vue';
import VueCtkDateTimePicker from 'vue-ctk-date-time-picker';
import 'vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css';

createApp(App).use(store).use(router).use(CKEditor).component('VueCtkDateTimePicker', VueCtkDateTimePicker).mount('#app')