<template>
  <div id="menu" class="menu fixed-top">
    <nav
      id="menubar"
      class="navbar navbar-expand-lg navbar-default navbar-custom collapsed"
      aria-expanded="false"
      v-bind:class="{ navbarOpen: show }"
    >
      <div class="container">
        <router-link class="routerLink" to="/"
          ><a style="margin: 0px" class="navbar-brand"
            ><i
              class="fa fa-home"
              aria-hidden="true"
              style="font-size: 40px"
            ></i></a
        ></router-link>
        <button
          class="navbar-toggler collapsed d-flex d-lg-none flex-column justify-content-around"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="toggler-icon top-bar"></span>
          <span class="toggler-icon middle-bar"></span>
          <span class="toggler-icon bottom-bar"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <div class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item m-1 fo">
              <router-link class="routerLink" to="/AllNews">
                <a class="nav-link">Hírek</a>
              </router-link>
            </li>

            <li class="nav-item dropdown m-1 fo">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Szabályzatok
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link
                    v-for="menupont in szabalyzatok"
                    v-bind:key="menupont.Id"
                    class="routerLink dropdown-item"
                    :to="'/Szabalyzat?id=' + menupont.Id.toString()"
                    >{{ menupont.Nev }}</router-link
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item dropdown m-1 fo">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Tájékoztatók
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link
                    v-for="menupont in tajekoztatok"
                    v-bind:key="menupont.Id"
                    class="routerLink dropdown-item"
                    :to="'/Tajekoztato?id=' + menupont.Id.toString()"
                    >{{ menupont.Nev }}</router-link
                  >
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://njt.hu/"
                    target="_blank"
                    >Jogszabálytár</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://ikk.hu/files/tajekoztato/fuzet.html"
                    target="_blank"
                    >Tájékoztató füzet az alapszakmákról</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://szakkepesites.ikk.hu/"
                    target="_blank"
                    >Tájékoztató képesítő vizsgák követelményeíről (PK)</a
                  >
                </li>
                <li>
                  <a
                    class="dropdown-item"
                    href="https://szakkepzes.ikk.hu/kkk-ptt"
                    target="_blank"
                    >Tájékoztató szakmai vizsgákról (KKK)</a
                  >
                </li>
              </ul>
            </li>
            <li class="nav-item m-1 fo">
              <router-link class="routerLink" to="/Minosegpolitika">
                <a class="nav-link">Minőségpolitika</a>
              </router-link>
            </li>
            <li class="nav-item dropdown m-1 fo">
              <a
                class="nav-link active dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                >Rólunk</a
              >
              <ul
                class="dropdown-menu"
                id="navbarDropdown"
                aria-labelledby="navbarDropdown"
              >
                <li>
                  <router-link class="routerLink dropdown-item" to="/Kapcsolat">
                    <a class="nav-link dropdown-item">Kapcsolat</a>
                  </router-link>
                </li>
                <li><hr class="dropdown-divider" /></li>
                <li><router-link class="routerLink dropdown-item" to="/GYIK">
                    <a class="nav-link dropdown-item">GYIK</a>
                  </router-link></li>
              </ul>
            </li>

            <!--li class="nav-item dropdown m-1 fo">
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Admin
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLinkLeft"
              >
                <li class="nav-item">
                  <router-link class="routerLink dropdown-item" to="/Login"
                    >Login</router-link
                  >
                </li>
              </ul>
            </li-->
            <li
              v-if="this.$store.state.uId != 'o'"
              class="nav-item dropdown m-1 fo"
            >
              <a
                class="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdown"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Admin menü
              </a>
              <ul
                class="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLinkRight"
              >
                <li>
                  <router-link
                    class="routerLink dropdown-item"
                    to="/AddNewsList"
                    >Hírek karbantartása</router-link
                  >
                </li>
                <li>
                  <a class="dropdown-item" href="#">
                    &laquo; Törzsadatok karbantartása
                  </a>
                  <ul
                    class="dropdown-menu dropdown-submenu dropdown-submenu-left"
                  >
                    <li>
                      <router-link
                        class="routerLink dropdown-item"
                        to="/Felhasznalok"
                        >Felhasználók</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="routerLink dropdown-item"
                        to="/Agazatok"
                        >Ágazatok</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="routerLink dropdown-item"
                        to="/AddSzabalyzat"
                        >Szabályzatok</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="routerLink dropdown-item"
                        to="/Szakmak"
                        >Szakmák</router-link
                      >
                    </li>
                    <li>
                      <router-link
                        class="routerLink dropdown-item"
                        to="/AddTajekoztato"
                        >Tájékoztatók</router-link
                      >
                    </li>
                  </ul>
                </li>
                <li>
                  <a class="dropdown-item" href="#" @click="logoutClick"
                    >Kijelentkezés</a
                  >
                </li>
              </ul>
            </li>
          </div>
        </div>
      </div>
    </nav>
    <!--router-view/-->
  </div>
</template>

<script>
import $ from "../../node_modules/jquery/dist/jquery";
import axios from "axios";

export default {
  name: "MenuBar",
  data() {
    return {
      szabalyzatok: [],
      tajekoztatok: [],
      show: {},
    };
  },

  methods: {
    refreshData() {
      axios.get(this.$store.state.API_URL + "Szabalyzatok").then((response) => {
        let temp = response.data;
        for (let i = 0; i < temp.length; i++) {
          temp[i].Id = i + 1;
          if (temp[i].Aktiv == 1) {
            this.szabalyzatok.push(temp[i]);
          }
        }
        //console.log(this.szabalyzatok);
      });
      axios.get(this.$store.state.API_URL + "Tajekoztatok").then((response) => {
        let temp = response.data;
        for (let i = 0; i < temp.length; i++) {
          temp[i].Id = i + 1;
          if (temp[i].Aktiv == 1) {
            this.tajekoztatok.push(temp[i]);
          }
        }
      });
    },
    closeMenu() {
      $("#navbarNav").collapse("hide");
    },
    logoutClick() {
      axios
        .post(this.$store.state.API_URL + "Logout?uid=" + this.$store.state.uId)
        .then((response) => {
          this.$store.state.uId = "o";
          console.log(response);

          this.$router.push({ path: "/" });
        });
    },
  },
  mounted: function () {
    this.refreshData();
  },
};
</script>

<style scoped>
.dropdown-item {
  font-size: 14px;
}
.dropdown-menu li {
  position: relative;
}
.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}
.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}
.dropdown-menu > li:hover > .dropdown-submenu {
  display: block;
}
li.fo {
  margin-left: 20px !important;
  margin-right: 20px !important;
}
.routerLink:hover {
  /*background:#d0b482 ;  */
  color: white;
}

.dropdown-menu {
  background-color: #9ea2a5;
  font-size: 20px;
  border-radius: 10px;
}

.dropdown-menu li a {
  color: white;
}

.nav-link {
  color: white !important;
}

.navbar-default {
  background-color: #194189 !important;
  font-size: 20px;
}

.navbar-brand {
  color: rgb(209, 237, 243);
  font-weight: bold;
  font-size: 24px !important;
  margin-left: 40px;
}

.nav-link:hover,
.navbar-brand:hover {
  color: white !important;
}

.navbar-toggler {
  width: 20px;
  height: 20px;
  position: relative;
  transition: 0.5s ease-in-out;
}

.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none;
  box-shadow: none;
  border: 0;
}

.navbar-toggler span {
  margin: 0;
  padding: 0;
}

.toggler-icon {
  display: block;
  position: absolute;
  height: 3px;
  width: 100%;
  background: linear-gradient(
    263deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(70, 71, 71) 100%
  );
  border-radius: 1px;
  opacity: 1;
  left: 0;
  transform: rotate(0deg);
  transition: 0.25s ease-in-out;
}

.middle-bar {
  margin-top: 0px;
}

/* when navigation is clicked */

.navbar-toggler .top-bar {
  margin-top: 0px;
  transform: rotate(135deg);
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  margin-top: 0px;
  transform: rotate(-135deg);
}

/* State when the navbar is collapsed */

.navbar-toggler.collapsed .top-bar {
  margin-top: -20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  margin-top: 20px;
  transform: rotate(0deg);
}

.navbar-toggler.collapsed .toggler-icon {
  background: linear-gradient(
    263deg,
    rgba(255, 255, 255, 1) 0%,
    rgb(70, 71, 71) 100%
  );
}

#menu {
  margin-top: 0px;
  position: fixed;
  /*z-index: 11;*/
}
#menubar {
  margin-top: 80px;
  padding: 4px;
}

.dropdown-menu > li > a:hover,
.routerLink > a:hover {
  background-color: #8f8d8b !important;
  color: white;
}
/*.navbar-toggler {
    border-color: rgba(68, 193, 243, 0.856);
}
.navbar-toggler:active {
  border-color: blue !important;
}


.navbar-toggler,
.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
    outline: none;
    border: none;
    box-shadow: none;
}
a{
  margin-top: 10px;
}
#menu{
  margin-top: -2px;
}
#menubar{
  margin-top: 80px;
}
.navbar-nav > li > .dropdown-menu { background-color: white; }
.nav-link {
color: white !important;
}
.navbar-toggler-icon{
  color: white !important;
}*/
</style>
